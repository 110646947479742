import React, { useEffect, useState } from 'react'
import RecHeaderAndFooter from '../../layouts/RecHeaderAndFooter';
import { css } from '../../../styled-system/css';
import { HeadFC, navigate } from 'gatsby';
import ResponsiveImage from '../../components/photographie/ResponsiveImage';
import { Profils } from '../../types/profils';
import Vignette from '../../components/recrutement/Vignette';

export default function profils() {
  const [search, setSearch] = useState('');

  useEffect(() => {
    if (process.env.GATSBY_NO_PROFILES === 'true') {
      navigate('/recrutement/');
    }
  }, []);

  return (
    <RecHeaderAndFooter>
      <div className={css({
        display: 'flex',
        flexDirection: 'row',
        '@media (max-width: 768px)': {
          flexDirection: 'column',
          position: 'relative',
          mb: '30vw'
        },
        aspectRatio: '2.6',
        minHeight: '450px',
        maxWidth: '100%',
        alignItems: 'center',
        gap: '5%',
        background: '#0E111C',
      })}>
        {/*----------------------------Image --------------------------------*/}
        <ResponsiveImage className={css({
          width: '40%',
          height: '100%',
          zIndex: '2',
          left: '0%'
        })} imageName='QuentinPro' />
        {/*----------------------------Image  end--------------------------------*/}
        <div className={css({
          width: '50%', height: '60%', pl: '5%', display: 'flex', flexDir: 'column', justifyContent: 'start',
          '@media (max-width: 768px)': {
            width: '100%',
            gap: '5vw',
            mt: '10vw',
          },
        })}>
          <h1 className={css({ color: 'white', fontSize: '40px', height: '33%' })}>
            Notre sélection anonyme
            de profils IT            </h1>
          <p className={css({ color: '#7983A7', fontSize: '20px', flex: '1', })}>
            Découvrez les candidats qui vous intéressent parmi notre liste de profils qualifiés.
          </p>
          <button className={css({
            alignSelf: 'start',
            color: 'white',
            padding: '5px',
            width: '40%',

            height: '56px',
            background: '#006BFF',
            fontWeight: '700',
            fontSize: '20px',
            cursor: 'pointer',
          })}
            onClick={() => navigate('/recrutement')}>Découvrir les profils</button>
        </div>
      </div>

      <div className={css({ width: '100%', display: 'flex', justifyContent: 'center' })}>
        <input className={css({ background: '#171F3D', height: '50px', m: '30px 0', width: '30%', borderRadius: '50px', color: 'white', pl: '10px' })} type='text' placeholder='Développeur, ingénieur logiciel...' onChange={(e) => setSearch(e.target.value)} value={search}></input>
      </div>
      <div>
        {search === '' ? Profils.map((profil, index) => (<Vignette key={index} profil={profil}></Vignette>))
          : Profils.map((profil, index) => ((profil.fonction.includes(search.toLowerCase()) || profil.mobilite.includes(search.toLowerCase())) && <Vignette key={index} profil={profil}></Vignette>))}
      </div>
    </RecHeaderAndFooter>
  )
}

export const Head: HeadFC = () => <title>Nos Profils | Digit Universe</title>