import React from 'react'
import { Profil } from '../../types/profils'
import { css } from '../../../styled-system/css';
import ResponsiveImage from '../photographie/ResponsiveImage';

type VignetteProps = {
  profil: Profil;
}

export default function Vignette({ profil }: VignetteProps) {
  return (
    <div className={css({
      padding: '50px 20px',
      width: '50%',
      height: '300px',
      display: 'flex',
      flexDir: 'row',
      justifyContent: 'space-around',
      border: '2px solid black'
    })}>
      <div>
        <h3>{profil.fonction}</h3>
        <p>Secteur : {profil.secteur}</p>
        <p>Type de contrat : {profil.typeContrat}</p>
      </div>
      <ResponsiveImage className={css({ width: '50%' })} imageName='QuentinPro'></ResponsiveImage>
    </div>
  )
}
