export type Profil = {
    fonction : string,
    secteur : string,
    typeContrat : string,
    remuneration : string,
    disponibilité : string,
    mobilite : string
}

export const Profils  : Profil[] = [
    {
        fonction : 'Ingénieur logiciel',
        secteur : 'Développeur logiciel',
        typeContrat :'CDI',
        remuneration:'100',
        disponibilité:'quelque chose',
        mobilite : 'France',
    }
]

